<template>
  <b-container fluid>
    <!--UPLOAD-->
    <b-form v-if="isInitial || isSaving" enctype="multipart/form-data" novalidate>
      <div class="dropbox">
        <input
          type="file"
          class="input-file"
          @change="loadImage"
          :accept="imgAccept"
          :disabled="isSaving"
        />
          <p v-if="isInitial">
            Drag your file here to begin<br> or click to browse
          </p>
          <p v-if="isSaving">
            Uploading file...
          </p>
      </div>
    </b-form>
    <!--SUCCESS-->
    <!-- div v-if="isLoaded">
      <h2>File loaded successfully.</h2>
      <p>
        <a href="javascript:void(0)" @click="reset()">Upload again</a>
      </p>
    </div -->
    <!--FAILED-->
    <div v-if="isFailed">
      <h2>Uploaded failed.</h2>
      <pre>{{ uploadError }}</pre>
      <p>
        <a href="javascript:void(0)" @click="reset()">Try again</a>
      </p>
    </div>
  </b-container>
</template>

<script>
import { mapActions } from 'vuex'

// This function is used to detect the actual image type, 
function getMimeType(file, fallback = null) {
	const byteArray = (new Uint8Array(file)).subarray(0, 4);
    let header = '';
    for (let i = 0; i < byteArray.length; i++) {
       header += byteArray[i].toString(16);
    }
	switch (header) {
        case "89504e47":
            return "image/png";
        case "47494638":
            return "image/gif";
        case "ffd8ffe0":
        case "ffd8ffe1":
        case "ffd8ffe2":
        case "ffd8ffe3":
        case "ffd8ffe8":
            return "image/jpeg";
        default:
            return fallback;
    }
}

const 
  API_UPLOAD_DIR_PROP = 'slug',
  STATUS_INITIAL = 0,
  STATUS_LOADED = 2,
  STATUS_SAVING = 3,
  STATUS_SUCCESS = 4,
  STATUS_FAILED = 5

export default {
  name: 'MediaUpload',
  props: {
    subDir: {
      type: String,
      default: null
    },
    filePropName: {
      type: String,
      default: () => ('file')
    },
    imgAccept: {
      type: String,
      default: () => ('image/*')
    }
  },
  data() {
    return {
      uploadedFile: {},
      uploadError: null,
      currentStatus: null,
      uploadDirProp: API_UPLOAD_DIR_PROP,
      image: {
				src: null,
				type: null,
        name: null
			}
    }
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL
    },
    isLoaded() {
      return this.currentStatus === STATUS_LOADED
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED
    }
  },
  methods: {
    ...mapActions(['postMedia']),
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL
      this.uploadedFile = {}
      this.uploadError = null
      this.image = {
				src: null,
				type: null,
        name: null
			}
    },
    XloadImage(event) {
			// Reference to the DOM input element
			const { files } = event.target
			// Ensure that you have a file before attempting to read it
			if (files && files[0]) {
				// 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
				if (this.image.src) {
					URL.revokeObjectURL(this.image.src)
				}
				// 2. Create the blob link to the file to optimize performance:
				const blob = URL.createObjectURL(files[0])
				
				// 3. determine a file mime type
				
				// Create a new FileReader to read this image binary data
				const reader = new FileReader()
				// Define a callback function to run, when FileReader finishes its job
				reader.onload = (e) => {
					// Note: arrow function used here, so that "this.image" refers to the image of Vue component
					this.image = {
						// Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
						src: blob,
						// Determine the image type to preserve it during the extracting the image from canvas:
						type: getMimeType(e.target.result, files[0].type),
            // set the file name
            name: files[0].name
					};
          this.currentStatus = STATUS_LOADED
				};
				// Start the reader job - read file as a data url (base64 format)
				reader.readAsArrayBuffer(files[0])
			}
		},
    loadImage (event) {
      const { files } = event.target
      // handle file changes
      if (!files.length) return
      this.currentStatus = STATUS_SAVING
      // append the data to FormData
      const formData = {
        user: this.currentUser.id,
        slug: this.currentBusiness.uriName + '/sponsors',
        business: this.currentBusiness.id,
        file: files[0]
      }
      // save it
      this.postMedia(formData).then(resp => {
        this.uploadedFile = resp
        this.$emit('uploaded', this.uploadedFile)
        this.reset()
      }, err => {
        this.uploadError = err.response.data['hydra:description']
        this.currentStatus = STATUS_FAILED
      })
    }
  },
  watch: {
    currentStatus (val)  {
      if (val === STATUS_LOADED) {
        this.save()
      }
    }
  },
  mounted() {
    this.reset()
  },
	destroyed() {
		// Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
		if (this.image.src) {
			URL.revokeObjectURL(this.image.src)
		}
	}
}
</script>

<style lang="scss">
  .dropbox {
    outline: 2px dashed grey; /* the dash box */
    outline-offset: -10px;
    background: lightcyan;
    color: dimgray;
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;
  }
  
  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }
  
  .dropbox:hover {
    background: lightblue; /* when mouse over to the drop zone, change color */
  }
  
  .dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
  }
</style>
