<template>
  <div className="animated">
    <b-row>
      <b-col><p><a :href="'http://' + sitePublicDomain + '/' + currentBusiness.uriName + '/sponsor-requests'" target="_blank">Sponsor&nbsp;Instructions&nbsp;URL</a></p></b-col>
      <b-col cols="2" class="text-right"><b-btn v-b-modal.modal-campaign>New Campaign</b-btn></b-col>
    </b-row><hr />
        <v-server-table 
          class="bg-light p-2" 
          name="dataTableSponsorList" 
          ref="datatable-sponsors"
          :columns="columns" 
          :url="url" 
          :options="options" 
          :theme="theme" 
          id="sponsorsTable" 
          :preserveState="preserveState"
        >
          <template slot="imgSrc" slot-scope="props">
            <a :href="props.row.imgSrc" target="_blank"><b-img :src="props.row.imgSrc" height="75" /></a>
          </template>
          <template slot="url" slot-scope="props">
            <a :href="props.row.url" target="_blank">{{ props.row.url }}</a>
          </template>
          <template slot="actions" slot-scope="props">
            <b-button @click="campaignArchive(props.row.id)" size="sm" block>Archive&nbsp;Campaign</b-button>
            <b-button @click="sponsorArchive(props.row.sponsor.id)" size="sm" block>Archive&nbsp;Sponsor</b-button>
          </template>
        </v-server-table>
        <b-modal
          id="modal-campaign"
          ref="modal-campaign"
          title="New Campaign"
          size="xl"
          ok-only
        >
          <SponsorsCampaignForm
            @submitted="onSubmitted()" 
          />
          <template v-slot:modal-ok>
            Close
          </template>
        </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import { HTTP } from '../../services/http-common'
import { mapActions, mapGetters } from 'vuex'
import { ServerTable, Event } from 'vue-tables-2-premium'
import SponsorsCampaignForm from '@/components/SponsorsCampaignForm'
var businessId
Vue.use(ServerTable)
export default {
  name: 'Sponsors',
  data () {
    return {
      options: {
        filterByColumn: true,
        orderBy: { 
          column: 'business.areaName',
          ascending: true
        },
        perPage: 30,
        perPageValues: [30,60,90],
        headings: {
          id: 'Rept ID', 
          'business.areaName': 'Franchise', 
          'sponsor.label': 'Sponsor',
          dateCreated: 'Created',
          imgSrc: 'Image',
          'clicks.length': 'Clicks'
        },
        sortable: [],
        filterable: ['business.areaName', 'sponsor.label', 'url'],
        sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
        pagination: {
          chunk: 5,
          edge: false,
          nav: 'scroll'
        },
        requestFunction (data) {
          return HTTP.get(this.url, {
              params: data
          }).catch(function (e) {
              this.dispatch('error', e);
          }.bind(this))
        },
        requestAdapter (data) {
          let query = {}
          query.business = businessId
          query['sponsor.archived'] = 0 // @TODO change to boolean after API is updated to accept
          query.archived = 0 // @TODO change to boolean after API is updated to accept
          for (let key in data.query) {
            switch (key) {
              case 'dateCreated': 
                let param = 'dateCreated[after]'
                query[param] = data.query.dateCreated.start
                param = 'dateCreated[before]'
                query[param] = data.query.dateCreated.end
                break
              default: 
                query[key] = data.query[key]
            }
          }
          query.page = data.page ? data.page : '1'
          if (data.orderBy) {
            let param = 'order[' + data.orderBy + ']'
            query[param] = data.ascending ? 'asc' : 'desc'
          } else {
            query['order[business.areaName]'] = 'asc'
          }
          // console.log('requestAdapter query', query)
          return query
        },
        responseAdapter ({data}) {
          return {
            data: data['hydra:member'],
            count: data['hydra:totalItems']
          }
        }
      },
      preserveState: true,
      useVuex: true,
      theme: 'bootstrap4',
      template: 'default',
      viewUid: null
    }
  },
  computed: {
    ...mapGetters([
      'business'
    ]),
    url () {
      return 'sponsor_campaigns'
    },
    columns () {
      let columns = ['business.areaName', 'sponsor.label', 'imgSrc', 'url', 'clicks.length',  'actions']
      if (this.currentBusiness.id) {
        columns.splice(0,1)
      }
      return columns
    }
  },
  methods: {
    ...mapActions([
      'getSponsorCampaigns',
      'putSponsor',
      'putSponsorCampaign'
    ]),
    onSubmitted () {
      this.$refs['modal-campaign'].hide()
      this.$refs['datatable-sponsors'].refresh()
      this.showSuccess({ message: 'The campaign has been added' })
    },
    campaignArchive (id) {
      this.$bvModal.msgBoxConfirm('Archive this CAMPAIGN?').then(archive => {
        if (archive) {
          this.putSponsorCampaign({
            id: id,
            archived: true
          }).then(() => {
            this.$refs['datatable-sponsors'].refresh()
          })
        }
      })
    },
    sponsorArchive (id) {
      this.$bvModal.msgBoxConfirm('Archive this SPONSOR and all associated campaings?').then(async archive => {
        if (archive) {
          try {
            this.getSponsorCampaigns({ 'sponsor.id': id }).then(async sponsorCampaigns => {
              for (const i in sponsorCampaigns) {
                await this.putSponsorCampaign({
                  id: sponsorCampaigns[i].id,
                  archived: true
                })
              }
            })
            await this.putSponsor({
              id: id,
              archived: true
            })
            this.$refs['datatable-sponsors'].refresh()
          } catch (e) {
            console.error(e)
          }
        }
      })
    }
  },
  created () {
    businessId = this.currentBusiness.id
  },
  components: { SponsorsCampaignForm }
}
</script>