<template>
  <b-form @submit.prevent="onSubmit">
    <b-form-select v-if="!sponsorId"
      class="mb-4"
      v-model="selectedSponsorId" 
      :options="sponsorOptions"
      value-field="id"
      text-field="label"
    >
      <template v-slot:first>
        <option :value="null" disabled>-- Sponsor --</option>
      </template>
    </b-form-select>

    <!-- new sponsor -->
    <b-card v-if="selectedSponsorId === 'new'" 
      sub-title="Sponsor"
    >
      <b-form-input
        class="mt-2"
        v-model="newSponsorLabel"
        placeholder="Sponsor Label"
        required
      ></b-form-input>
    </b-card>

    <b-card v-if="selectedSponsorId"
      sub-title="Campaign"
    >
      <b-form-group
        v-if="useImgSrc"
        description="The URL of the image to use should begin with https://"
        label-for="campaign-imgSrc"
        :state="stateImgSrc"
      >
        <b-input-group class="mt-2">
          <b-form-input
            id="campaign-imgSrc"
            v-model.trim="campaign.imgSrc"
            placeholder="Image Source - e.g., https://example.com/file.jpg"
            :state="stateImgSrc"
            required
          ></b-form-input>
          <template #append>
            <b-dropdown>
              <b-dropdown-item @click="useImgSrc = false">Upload a file</b-dropdown-item>
            </b-dropdown>
          </template>
        </b-input-group>
      </b-form-group>

      <MediaUpload v-else @uploaded="onUploaded" />

      <b-form-group
        description="The URL of the target web page should begin with https://"
        label-for="campaign-imgSrc"
        :state="stateUrl"
      >
        <b-form-input
          class="mt-2"
          v-model.trim="campaign.url"
          placeholder="Link URL - e.g., https://example.com/page"
          :state="stateUrl"
          required
        ></b-form-input>
      </b-form-group>

      <b-btn
        class="mt-2"
        variant="success"
        type="submit"
        :disabled="busy"
        block
      >
        Save this campaign
      </b-btn>
    </b-card>
  </b-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MediaUpload from '@/components/MediaUpload'

export default {
  name: 'SponsorsCampaignForm',
  props: {
    sponsorId: {
      type: Number,
      default () {
        return null
      }
    }
  },
  components: {
    MediaUpload
  },
  data () {
    return {
      busy: false,
      selectedSponsorId: null,
      newSponsorLabel: null,
      sponsor: {},
      useImgSrc: true,
      campaign: {
        imgSrc: null,
        url: null
      }
    }
  },
  computed: {
    ...mapGetters([
      'sponsors'
    ]),
    stateImgSrc () {
      if (!this.campaign.imgSrc) {
        return null
      }
      if (!this.campaign.imgSrc.match(/^https/ig)) { // @XXX (.*)\.(jpg|jpeg|png)$
        return false
      }
      return true
    },
    stateUrl () {
      if (!this.campaign.url) {
        return null
      }
      if (!this.campaign.url.match(/^https/ig)) {
        return false
      }
      return true
    },
    sponsorOptions () {
      let sponsorOptions = [...this.sponsors]
      sponsorOptions.push({
        'id': 'new',
        label: 'New Sponsor Account'
      })
      return sponsorOptions
    }
  },
  methods: {
    ...mapActions([
      'getSponsors',
      'postSponsor',
      'resetSponsors',
      'postSponsorCampaign'
    ]),
    async onSubmit (e) {
      this.busy = true
      if (this.selectedSponsorId === 'new') {
        await this.doPostSponsor()
        await this.doPostCampaign()
      } else {
        await this.doPostCampaign()
      }
    },
    doPostSponsor () {
      return this.postSponsor({
        business: this.currentBusiness["@id"],
        label: this.newSponsorLabel
      }).then(sponsor => {
        this.sponsor = sponsor
      })
    },
    onUploaded (data) {
      console.log(data)
      this.useImgSrc = true
      this.campaign.imgSrc = 'https://api.nzonesports.com/media/' + data.contentUrl
    },
    doPostCampaign () {
      this.postSponsorCampaign({
        business: this.currentBusiness['@id'],
        sponsor: this.sponsor['@id'],
        imgSrc: this.campaign.imgSrc,
        url: this.campaign.url,
        starts: this.$moment()
      }).then(() => {
        this.$emit('submitted')
      })
    }
  },
  watch: {
    selectedSponsorId (val) {
      if (val === 'new') {
        return
      } else {
        this.newSponsorLabel = null
      }
      this.getSponsors({
        id: val
      }).then(sponsor => {
        this.sponsor = sponsor
      })
    }
  },
  created () {
    this.getSponsors({
      business: this.currentBusiness.id,
      archived: 0 // @TODO change to boolean after API is updated to accept
    })
  },
  mounted () {
    if (this.sponsorId) {
      this.selectedSponsorId = this.sponsorId
    }
  },
  destroyed () {
    this.resetSponsors()
  }
}
</script>